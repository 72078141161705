import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const Nav = () => {
  const { contentfulNavigation } = useStaticQuery(graphql`
    query {
      contentfulNavigation(slug: { eq: "headerNavigation" }) {
        navigationItems {
          ... on ContentfulNavigationItem {
            id
            url
            title
          }
          ... on ContentfulPage {
            id
            slug
            title
          }
        }
      }
    }
  `);

  const [menuHidden, setMenuHidden] = React.useState(true);
  const half = contentfulNavigation.navigationItems.length / 2;
  const ceil = Math.ceil(half);

  return (
    <nav className="fixed top-0 inset-x-0 bg-[#0d47a1] text-[#F0F0F0] flex flex-col md:items-center shadow-2xl z-50 opacity-[98%]">
      <div className="flex items-center block md:hidden p-4">
        <button type="button" onClick={() => setMenuHidden(!menuHidden)}>
          <svg
            className="w-6 h-6 text-white"
            x-show="!showMenu"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <Link className="mx-4" to="/">
          ICCR
        </Link>
      </div>
      <ul
        className={`${
          menuHidden ? 'hidden ' : ''
        }md:block mb-2 md:mb-0 whitespace-nowrap`}
      >
        {contentfulNavigation.navigationItems.map((item, i) => (
          <li
            className={`block md:inline-block border-0 hover:opacity-80 border-[#1565c0] ${
              i > half - 1 ? '-skew-x-12' : 'skew-x-12'
            } ${
              i !== ceil - 1
                ? i < half
                  ? 'md:border-r-2'
                  : 'md:border-l-2'
                : ''
            }`}
            key={i}
          >
            <Link
              className={`inline-block px-5 py-2 md:py-5 text-base md:text-sm md:uppercase tracking-wide ${
                i > half - 1 ? 'skew-x-12' : '-skew-x-12'
              }`}
              to={item.slug ? `/${item.slug}` : item.url}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
